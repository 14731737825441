import { ViewChild, Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '@services/order.service';
import { DialogService } from '@services/dialog.service';
import { OverlayService } from '@services/overlay.service';
import { DialogData } from '@models/dialog';
import { environment } from '@environments/environment';

import { BulkService } from '@services/bulk.service';
import { BulkStatusService } from '@services/bulk-status.service';

import { CSVOrder } from '@models/csv/order';
import { CSVItem } from '@models/csv/item';

@Component({
    selector: 'app-bulkfixedbar',
    templateUrl: './fixed-bar.component.html',
    styleUrls: ['./fixed-bar.component.scss']
})

export class BulkFixedBarComponent implements OnInit {

    constructor(
        private orderService: OrderService,
        private dialogService: DialogService,
        public overlayService: OverlayService,
        public bulk: BulkService,
        public bulkStatus: BulkStatusService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
    }

}

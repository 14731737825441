import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { OverlayService } from '@services/overlay.service';
import { DialogService } from '@services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { DialogData } from '@models/dialog';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers:  [ AuthenticationService ]
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
    loading = false;
    submitted = false;
    expired: string;
    returnUrl: string;
    dialogData: DialogData;
    overlay: boolean;
    textButton: string = "Iniciar Sesión";

    constructor(
  		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private dialogService: DialogService,
        private overlayService: OverlayService
      
    ){ 
  	    if (this.authenticationService.currentUserValue) {
            this.router.navigate(['home/dashboard']);
        }
    }

    openDialog() {
        const dialogRef = this.dialogService.openDialog(this.dialogData, {disableClose: false});
    }

    ngOnInit() {
        this.expired = this.route.snapshot.paramMap.get("expired");
        
        console.log(this.expired);
  	    this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.overlayService.currentState.subscribe(state => this.overlay = state);
        if(this.expired === "expired") {
            this.dialogData = {
                title: "",
                class: false,
                message: "Su sesión ha caducado, inicie sesión nuevamente.",
                orderId: "",
                sgId: "",
                showOKBtn: true
            };
            this.openDialog();
        }
    }

    get f() { return this.loginForm.controls; }


    onSubmit() {
        this.overlayService.changeState(true);
        this.textButton = "Iniciando....";
        this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
            data => {
                localStorage.setItem('currentUser', JSON.stringify(data.message));
                localStorage.setItem('token',data.message.token);
                this.router.navigate(['home/dashboard']);
                //location.reload(true);
            },
            error => {                        
                if(error.error.code == 401 || error.error.code == 403) {
                    this.dialogData = {
                        title: "",
                        class: false,
                        message: "Las credenciales ingresadas no coinciden con ninguna cuenta.",
                        orderId: "",
                        sgId: "",
                        showOKBtn: true
                    };    
                } else {
                    this.dialogData = {
                        title: "",
                        class: false,
                        message: "Ha ocurrido un error inesperado, intente nuevamente o póngase en contacto con el soporte de Omnix.",
                        orderId: "",
                        sgId: "",
                        showOKBtn: true
                    };
                }
                this.overlayService.changeState(false);
                this.textButton= "Iniciar Sesión";
                this.openDialog();                        
            }
        );
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZonesEditorComponent } from './components/zones-editor/zones-editor.component';

@NgModule({
  declarations: [ZonesEditorComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ZonesEditorComponent
  ]
})
export class SchedulingMapsModule { }

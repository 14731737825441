import { Injectable, ElementRef } from '@angular/core';
import { PolygonStyles } from '../constants/polygon.styles';
import { CoordsInterface } from '../interfaces/coords.interface';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '@services/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class MapsService {

    map: google.maps.Map;

    mapEvent = google.maps.event;

    polygons: any[] = [];

    gcoords: google.maps.LatLng;

    mapOptions: google.maps.MapOptions = {
        streetViewControl: false,
        mapTypeControl: false
    };

    isToggleMode = false;

    drawingModes = google.maps.drawing.OverlayType;

    polygonStyles = new PolygonStyles();

    selectedPolygon: google.maps.Polygon = null;

    drawingManager: google.maps.drawing.DrawingManager;

    dialogData = {
        title: "",
        class: false,
        message: '',
        orderId: "",
        sgId: "",
        showOKBtn: true
    };

    constructor(
        private http: HttpClient,
        private dialogService: DialogService
    ) { }

    /**
     * Initialize a new map with the element passed.
     * @param mapElement Map ViewChild DIV
     * @return Google Maps Map
     */
    generateMap(mapElement: ElementRef) {
        this.map = new google.maps.Map(mapElement.nativeElement);
        return this.map;
    }

    setInitialCoords(coords: CoordsInterface, zoom: number) {
        this.gcoords = new google.maps.LatLng(coords.latitude, coords.longitude);
        this.mapOptions.center = this.gcoords;
        this.mapOptions.zoom = zoom;
        this.map.setOptions(this.mapOptions);
    }

    /**
    * Draw commune 
    * 
    * @var regionId string
    * @var communeId
    * 
    * @return void
    */
    drawCommune(regionId, communeId, initialZones): void {

        this.http.get(`assets/geojson/communes/000${regionId}-${communeId}.geojson`).subscribe((data) => {

            this.map.data.forEach(element => {
                if (element.getProperty('codigo_comuna')) {
                    this.map.data.remove(element);
                }
            });

            this.map.data.addGeoJson(data, { idPropertyName: 'type' });
            this.map.data.setStyle({
                fillColor: 'green',
                fillOpacity: 0.3,
                strokeWeight: 1.5,
                title: `${communeId}`
            });

            // Inital events
            this.btnAddHoleListener();
            this.btnAddPolygonNameListener();
            this.setInitialPolygons(initialZones);

        });

    }



    /**
     * Draw the initial polygons on the map if sent.
     *
     * @param polygons Coords array
     */
    setInitialPolygons(polygons: [][]) {
        this.polygons = [];
        const style = this.polygonStyles.unselected;

        polygons.forEach((coords: Array<any>, i) => {

            let polygon = [];
            coords.forEach((polyInfo: Object, j) => {

                for (let prop in polyInfo) {

                    if (prop === 'type' && polyInfo[prop] == 'include') {
                        polygon.push(polyInfo['coords']);
                    }

                    if (prop === 'type' && polyInfo[prop] == 'exclude') {
                        polygon.splice(0, 0, polyInfo['coords']);
                    }

                }

            });

            const path = new google.maps.Polygon(
                Object.assign({
                    paths: polygon
                }, style));
            path.setMap(this.map);
            path['name'] = coords[0]['name'];
            path['uuid'] = coords[0]['uuid'];
            this.addPolygonListeners(path);
        });

    }

    /**
     * Create a new instance of Drawing Manager.
     * Sets the current drawing manager into the generated map.
     * Initialize map listeners and set styles for the polygons.
     */
    initDrawingManager() {
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    this.drawingModes.POLYGON
                ]
            },
            polygonOptions: this.polygonStyles.selected
        });
        this.drawingManager.setMap(this.map);
        this.addMapListeners();

    }

    /**
     * Returns the list of all polygon coords.
     */
    getPolygonCoords() {

        let coords = [];

        this.polygons.forEach((polygon, indx) => {
            const arr = polygon.getPaths().getArray();
            let output = [];
            let polygonName = polygon['name'];
            let polyUuid = polygon['uuid'];

            if(typeof polyUuid !== 'undefined'){
                // 
            }else{
                polygon['uuid'] = Date.now(); 
            }

            arr.forEach((elem, index) => {
                let info = {};
                
                if(typeof polygonName !== 'undefined'){
                    info['name'] = polygonName;
                }else{
                    info['name'] = `Polígono #${indx+1}`; 
                }

                if (index <= 0) {
                    info['type'] = 'exclude';
                } else {
                    info['type'] = 'include';
                }

                let _coords = elem.getArray();
                let geoInfo = [];

                _coords.forEach(obj => {
                    geoInfo.push({ lat: obj.lat(), lng: obj.lng() });
                });
                
                info['coords'] = geoInfo;
                info['uuid'] = polygon['uuid'];

                output.push(info);
            });

            coords.push(output);
        });
        
        
        /*let coords2 = []; 
        this.polygons.forEach(polygon => {
            const arr = polygon.getPath().getArray();
            const shape = [];
            arr.forEach(obj => {
                shape.push({lat: obj.lat(), lng: obj.lng()});
            });
            coords2.push(shape);
        });*/
        
        return coords;
    }

    /**
     * Generate Google Map listeners
     * See each listener method for more specs.
     */
    addMapListeners() {
        this.mapEvent.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
            polygon['uuid'] = Date.now();
            this.addPolygonListeners(polygon);
        });
    }

    centerPolygon = function (arr) {
        var x = arr.map(xy => xy.lat);
        var y = arr.map(xy => xy.lng);
        var cx = (Math.min(...x) + Math.max(...x)) / 2;
        var cy = (Math.min(...y) + Math.max(...y)) / 2;
        return { lat: cx, lng: cy };
    }

    customPolygonHole(coords) {
        let mvcArray = new google.maps.MVCArray();
        mvcArray.push(new google.maps.LatLng({ lat: (coords.lat), lng: coords.lng }));
        mvcArray.push(new google.maps.LatLng({ lat: (coords.lat + 0.03), lng: (coords.lng - 0.04) }));
        mvcArray.push(new google.maps.LatLng({ lat: (coords.lat + 0.03), lng: coords.lng }));
        return mvcArray;
    }

    btnAddPolygonNameListener() {
        const _doc: Document = document;
        const btnAddPolygonName: HTMLElement = _doc.getElementById('btn-add-polygon-name');
        const polygonName: HTMLElement = _doc.getElementById('polygonName');

        btnAddPolygonName.addEventListener('click', (event) => {
            this.dialogData.class = false; 

            if ((polygonName['value'].trim()).length && this.selectedPolygon) {
                let _polyName: string = polygonName['value'].trim();
                let _error = false;

                this.polygons.forEach(_data => {
                    if(_data.hasOwnProperty('name')){
                        if(_polyName === _data['name'] &&
                            _data != this.selectedPolygon
                        ){
                            _error = !_error;
                        }
                    }
                });

                if(_error){
                    this.dialogData.message = 'El nombre ya está siendo utilizado en un polígono';                    
                    this.openDialog();
                }else{
                    this.dialogData.class = true;
                    this.dialogData.message = 'Nombre cambiado con éxito';
                    this.openDialog();
                    this.selectedPolygon['name'] = _polyName;
                }
                
            }else{                               
                if(!this.selectedPolygon){                    
                    this.dialogData.message = 'Debes seleccionar un polígono';                    
                }else{
                    this.dialogData.message = 'Nombre inválido';
                }
                this.openDialog(); 
            }
        });

    }

    /**
     * TODO: Move to utils.service 
    */
    openDialog() {
        const dialogRef = this.dialogService.openDialog(this.dialogData, { disableClose: false });
    }

    btnAddHoleListener() {
        let btnAddHole = document.getElementById('btn-add-hole');

        btnAddHole.addEventListener('click', (event) => {

            if (this.selectedPolygon) {
                let vertices = this.selectedPolygon.getPaths();
                let content = [];

                for (var i = 0; i < vertices.getLength(); i++) {
                    var _vertice = vertices.getAt(i);

                    for (var j = 0; j < _vertice.getLength(); j++) {
                        var _verticee = _vertice.getAt(j);
                        content.push({ lat: (_verticee.lat()), lng: _verticee.lng() });
                    }

                }

                let path: any = this.customPolygonHole(this.centerPolygon(content));
                vertices.push(path);
                this.selectedPolygon.setPaths(this.selectedPolygon.getPaths());

            }

        });

    }

    /**
     * Initiate Polygon events and add them to the array.
     *
     * @param polygon google.maps.Polygon
     */
    addPolygonListeners(polygon: google.maps.Polygon) {

        this.mapEvent.addListener(polygon, 'click', (event) => {
            this.polygonSelected(polygon);
        });

        this.mapEvent.addListener(polygon, 'rightclick', (event) => {
            this.polygonRightClick(event, polygon);
        });

        this.mapEvent.addListener(polygon.getPath(), 'set_at', (event) => {
            this.setPolygonPoint(event, polygon);
        });

        this.mapEvent.addListener(polygon.getPath(), 'insert_at', (event) => {
            this.insertPolygonPoint(event, polygon);
        });

        this.mapEvent.addListener(polygon.getPath(), 'remove_at', (event) => {
            this.removePolygonPoint(event, polygon);
        });

        this.polygons.push(polygon);

        this.polygonSelected(polygon);
    }

    /**
     * Selected polygon handler
     * Enable styles for selected and unselected polygons.
     * @param polygon Current Polygon
     */
    polygonSelected(polygon: google.maps.Polygon) {
        const index = this.polygons.indexOf(polygon);
        const _doc: Document = document;
        const polygonName: HTMLElement = _doc.getElementById('polygonName');

        this.polygons.forEach(p => {
            p.setOptions(this.polygonStyles.unselected);
        });

        if (index !== -1) {
            this.polygons[index].setOptions(this.polygonStyles.selected);
        }

        if (typeof polygon['name'] != 'undefined') {
            polygonName['value'] = polygon['name'];
        } else {
            polygonName['value'] = '';
        }

        this.selectedPolygon = polygon;
    }

    /**
     * Toggle polygons as editable
     */
    toggleEditMode() {
        const self = this;

        this.isToggleMode = !this.isToggleMode;

        if (this.isToggleMode === true) {
            this.drawingManager.setOptions({ drawingControl: true });
        } else {
            this.drawingManager.setOptions({ drawingControl: false });
        }

        this.polygons.forEach(polygon => {
            polygon.setDraggable(true); // TODO: Validate this crontrols
            polygon.setEditable(self.isToggleMode);
        });

    }

    /**
     * Event for Polygon Right Click
     * @param event Event listener
     * @param polygon Current Polygon
     */
    polygonRightClick(event, polygon: google.maps.Polygon) {
        if (event.path !== null && event.vertex !== null) {
            const path = polygon.getPath();
            if (path.getLength() > 3) {
                path.removeAt(event.vertex);
            }
        }
    }

    setPolygonPoint(event, polygon: google.maps.Polygon) {
        // TODO: Set polygon point
    }

    insertPolygonPoint(event, polygon: google.maps.Polygon) {
        // TODO: Insert polygon point
    }

    removePolygonPoint(event, polygon: google.maps.Polygon) {
        // TODO: Remove polygon point
    }

    /**
     * Removes from the map the selected polygon
     */
    removeSelectedPolygon() {
        if (this.selectedPolygon) {
            const confirmOpt = confirm('¿Deseas eliminar este poligono?');

            if (confirmOpt) {
                const _doc: Document = document;
                const polygonName: HTMLElement = _doc.getElementById('polygonName');

                this.selectedPolygon.setMap(null);
                const index = this.polygons.indexOf(this.selectedPolygon);

                if (index !== -1) {
                    this.polygons.splice(index, 1);
                }
                this.selectedPolygon = null;
                polygonName['value'] = '';

            }
        }
    }
}

export class DialogData {
    title: string;
    class: boolean;
	message: string;
	orderId: string;
	sgId: string;
    showOKBtn: boolean;
    showCancelBtn?: boolean = false;
    form?:boolean = false;
}

import { Component, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MapsService } from '../../services/maps.service';
import { CoordsInterface } from '../../interfaces/coords.interface';
import { Router } from '@angular/router';


@Component({
  selector: 'omnix-zones-editor',
  templateUrl: './zones-editor.component.html',
  styleUrls: ['./zones-editor.component.scss']
})
export class ZonesEditorComponent implements AfterViewInit, OnInit {

    @ViewChild('zonesContainer', {static: false}) gMap: ElementRef;

    latitude = -33.447487;
    longitude = -70.673676;
    // TODO: calculate zoom based on commune size
    @Input() zoom = 10.5;  
    @Input() initialZones: [][] = [];
    @Output() sendZones = new EventEmitter();

    map: google.maps.Map;

    mapsService: MapsService;

    private courierInitialInfo:object; 

    constructor(
        mapsService: MapsService,
        private router:Router
    ) {
        this.mapsService = mapsService;
    }

    ngOnInit(){
      this.courierInitialInfo = this.getInitialInformation();
    }

    ngAfterViewInit() {
        this.map = this.mapsService.generateMap(this.gMap);
        this.mapsService.drawCommune(this.courierInitialInfo['region']['id'], this.courierInitialInfo['commune']['id'], this.initialZones);
        this.mapsService.setInitialCoords(this.getCoords(), this.zoom);
        //this.mapsService.setInitialPolygons(this.initialZones);
        this.mapsService.initDrawingManager();
    }


    /**
     * Remove selected polygon in map
     */
    removePolygon() {
        this.mapsService.removeSelectedPolygon();
    }

    /**
     * Saves all the polygons in the map and triggers an event.
     */
    savePolygons(event) {
        this.sendZones.emit(this.mapsService.getPolygonCoords());
    }

    /**
     * Get initial map coords sent by component
     */
    getCoords(): CoordsInterface {
        const coords = {
            latitude: this.courierInitialInfo['commune']['lat'],
            longitude: this.courierInitialInfo['commune']['lng']
        };
        return coords;
    }

    /**
    * Get initial information from the courier form
    *
    * @return object
    */
    private getInitialInformation():object{
      if(localStorage.getItem('courier-info')){
        return JSON.parse(localStorage.getItem('courier-info'))
      }
      this.router.navigate(['/home', 'scheduling', 'couriers']);
    }

    // TODO: Validate or remove this function
    getCenter() {
        if (this.initialZones.length) {
            const data = {
                lat: {
                    sum: 0,
                    total: 0
                },
                lng: {
                    sum: 0,
                    total: 0
                }
            };
            this.initialZones.forEach(zone => {
                zone.forEach(coord => {
                    data.lat.sum += coord['lat'];
                    data.lat.total++;
                    data.lng.sum += coord['lng'];
                    data.lng.total++;
                });
            });
            return {
                lat: (data.lat.sum / data.lat.total),
                lng: (data.lng.sum / data.lng.total)
            };
        } else {
            this.zoom = 7;
            return { lat: this.latitude, lng: this.longitude };
        }
    }

    toggleEditMode() {
        this.mapsService.toggleEditMode();
    }

}

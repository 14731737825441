import { Component, OnInit } from '@angular/core';
import { OverlayService } from '@services/overlay.service';

@Component({
	selector: 'app-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
	
	isVisible: boolean;

  	constructor(private overlayService: OverlayService) { }

  	ngOnInit() {
    	this.overlayService.currentState.subscribe(state => this.isVisible = state)
  	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AlertComponent } from '../alert/alert.component';
import { DialogComponent } from './dialog/dialog.component';
import { OverlayComponent } from './overlay/overlay.component';
import { FormsModule } from '@angular/forms';


import {
	MatInputModule,
    MatFormFieldModule,
    MatDialogModule
	} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AlertComponent,
    DialogComponent,
    OverlayComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AlertComponent,
    DialogComponent,
    OverlayComponent
  ],
  entryComponents: [DialogComponent]
})
export class ComponentsModule { }

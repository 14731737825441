import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { OverlayService } from '@services/overlay.service';
import { DialogService } from '@services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { DialogData } from '@models/dialog';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	passwordForm: FormGroup;
	tokenForm: FormGroup;
    submitted = false;
    dialogData: DialogData;
	overlay: boolean;
	errorMessage: string;
	token: string;

	constructor(
		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private dialogService: DialogService,
        private overlayService: OverlayService
	) {
		this.initializePasswordForm();
		this.initializeTokenForm();
	}

	get f() { return this.passwordForm.controls; }

	openDialog() {
		const dialogRef = this.dialogService.openDialog(this.dialogData, {disableClose: false});
	}

	ngOnInit() {
		this.overlayService.currentState.subscribe(state => this.overlay = state);
		this.validateToken();
	}

	initializePasswordForm() {
		this.token = this.route.snapshot.paramMap.get("token");

        this.passwordForm = this.formBuilder.group({
			token:[this.token],
			password:['',Validators.required],
			retryPassword:['',Validators.required]
        });
	}

	initializeTokenForm() {
        this.tokenForm = this.formBuilder.group({
			token:[this.token]
        });
	}

	validateToken() {
		this.authenticationService.validateToken(this.tokenForm.value)
		.pipe(first())
		.subscribe(
			data => {
				console.log("Token valido");
			},
			error => {
				this.errorMessage = "Ha ocurrido un error inesperado, intente nuevamente o póngase en contacto con el soporte de Omnix.";
				if(error.error.code == 401) {
					this.errorMessage = "El token generado para el cambio de contraseña ha caducado.";
				}
				this.overlayService.changeState(false);
				this.dialogData = {
					title: "",
					class: false,
					message: this.errorMessage,
					orderId: "",
					sgId: "",
					showOKBtn: true
				};
				this.openDialog();
				this.router.navigate(['/login']);
			}
		);
	}

	onSubmit() {

		this.submitted = true;
		if (this.passwordForm.invalid) {
			this.dialogData = {
				title: "",
				class: false,
				message: "Todos los campos son obligatorios.",
				orderId: "",
				sgId: "",
				showOKBtn: true
			};
			this.openDialog();
			return false;
		}


		if(this.f.password.value != this.f.retryPassword.value) {
			this.dialogData = {
				title: "",
				class: false,
				message: "Las contraseñas deben coincidir.",
				orderId: "",
				sgId: "",
				showOKBtn: true
			};
			this.openDialog();
			return false;
		}

		const newFormValue = this.passwordForm.value;
		delete newFormValue.retryPassword;

		this.overlayService.changeState(true);
		this.authenticationService.changePassword(newFormValue)
		.pipe(first())
		.subscribe(
			data => {
				this.overlayService.changeState(false);
				this.dialogData = {
					title: "",
					class: true,
					message: "La contraseña se cambio con exito!!",
					orderId: "",
					sgId: "",
					showOKBtn: true
				};
				this.openDialog();
				this.router.navigate(['/login']);
			},
			error => {
				this.errorMessage = "Ha ocurrido un error inesperado, intente nuevamente o póngase en contacto con el soporte de Omnix.";
				//if(error.error.code == 401) {
					//this.errorMessage = "Te enviamos un correo electrónico con un link para cambiar tu contraseña."
				//}
				this.overlayService.changeState(false);
				this.dialogData = {
					title: "",
					class: false,
					message: this.errorMessage,
					orderId: "",
					sgId: "",
					showOKBtn: true
				};
				this.openDialog();
			}
		);
  	}
}

export class PolygonStyles {

    selected = {
        strokeColor: '#EA064C',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: '#EA064C',
        fillOpacity: 0.35,
        editable: true,
        draggable: true
      };

      unselected = {
        strokeColor: '#354052',
        strokeOpacity: 0.7,
        strokeWeight: 4,
        fillColor: '#354052',
        fillOpacity: 0.30,
        editable: false,
        draggable: false
      };

}

import { Injectable } from '@angular/core';
import { OrderService } from '@services/order.service';
import { Router } from '@angular/router';

/**
* Bulk order service class
*/
@Injectable({ providedIn: 'root' })
export class BulkStatusService {

    UPLOAD_RESULTS_KEY = 'upload-results-status';

    FILE_OBJECT_KEY = 'bulk-file-object-status';

    totals = {
        ok: [],
        err: [],
        total: 0
    };

    uploadStartedId = -1;

    percent = 0;

    uploadFinished = false;

    currentFile = null;

    orderList = [];

    showBar = false;

    files = [];

    constructor(
        private order: OrderService,
        private router: Router
    ) {
        this.validateCurrentUpload();
        this.validateCurrentResults();
    }

    validateCurrentUpload() {
        const file = this.getFile();
        if (file) {
            this.files.push(file);
        }
    }

    validateCurrentResults() {
        const results = this.getUploadResults();
        if (results) {
            this.totals = results;
        }
    }

    startUpload(index) {
        this.restart();
        const file = this.files[index];
        this.uploadStartedId = index;
        this.currentFile = file;
        this.saveFile(file);
        for (const order in file.content) {
            if (order) {
                this.orderList.push( this.currentFile.content[order] );
            }
        }
        this.changeStatus(this.orderList[0]);
    }

    restart() {
        this.showBar = true;
        this.uploadFinished = false;
        this.totals.ok = [];
        this.totals.err = [];
        this.totals.total = 0;
        this.orderList = [];
        this.percent = 0;
    }

    goToBulkView() {
        this.router.navigate(['/home', 'orders', 'bulk-status']);
    }

    changeStatus(order) {
        if (order) {
            const index = this.orderList.indexOf(order);
            const next = this.orderList[index + 1];
            const orderId = order.externalId;

            this.order.statusChanger(order).subscribe(
                data => {
                    this.updatePercent(index);
                    this.updateResults(orderId, data.message, 'ok');
                    this.changeStatus(next);
                }, err => {
                    this.updatePercent(index);
                    this.updateResults(orderId, err.error.message, 'err');
                    this.changeStatus(next);
                }
            );
        } else {
            this.finishedUpload();
        }
    }

    updateResults(_id, _response, status) {
        this.totals[status].push({id: _id, response: _response });
        this.totals.total++;
        this.saveUploadResults(this.totals);
    }

    saveUploadResults(data) {
        localStorage.setItem(this.UPLOAD_RESULTS_KEY, JSON.stringify(data));
    }

    getUploadResults() {
        return JSON.parse(localStorage.getItem(this.UPLOAD_RESULTS_KEY));
    }

    saveFile(file) {
        localStorage.setItem(this.FILE_OBJECT_KEY, JSON.stringify(file));
    }

    getFile() {
        return JSON.parse(localStorage.getItem(this.FILE_OBJECT_KEY));
    }

    deleteFile() {
        this.restart();
        this.showBar = false;
        localStorage.removeItem(this.UPLOAD_RESULTS_KEY);
        localStorage.removeItem(this.FILE_OBJECT_KEY);
    }

    finishedUpload() {
        if (this.uploadStartedId !== -1) {
            this.showBar = false;
            const file = this.files[this.uploadStartedId].finished = true;
            this.uploadFinished = true;
            this.uploadStartedId = -1;
            this.saveFile(this.currentFile);
        }
    }

    updatePercent(current: number) {
        this.percent = Math.round(((current + 1) * 100 / this.orderList.length));
    }

}

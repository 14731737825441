import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogData} from '@models/dialog';
import {DialogComponent} from '@components/dialog/dialog.component';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialogService {
    private dialogRef: MatDialogRef<DialogComponent>;

    constructor(public dialog: MatDialog) { }

    openDialog(data: DialogData, additionalDialogConfigData?: any): MatDialogRef<DialogComponent> {

      	if (this.dialogRef) {
          	this.dialogRef.close();
      	}
      	this.dialogRef = this.dialog.open(DialogComponent, {
          	width: '500px',
          	data
      	});

		this.dialogRef.afterClosed().subscribe(result => {});

    	return this.dialogRef;
    }
    
    openFormDialog(data: DialogData, input):Observable<any>{
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '500px',
            data
          });

        return dialogRef.afterClosed();
    }
	  
	afterClose() : Observable<any> {
		return this.dialogRef.afterClosed();
	}
}

import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {User} from '@models/user';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

    public userLabel: string;
    public isActive: boolean = false;
    userId: string;
    userData: User

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private eRef: ElementRef,
        private authenticationService: AuthenticationService) {

    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        let element = event.target.className;
        if(element == "text__user" || element == "text__info" || element == "customer-welcome" || element == "customer-welcome active") {
            this.isActive = !this.isActive;
        }else{
            if(this.isActive)
                this.isActive = false;
        }
    }

    ngOnInit(){
        this.userData = JSON.parse(localStorage.getItem('currentUser'));
    }

    logout(){
        this.authenticationService.logout();
        this.router.navigate(['login']).then(() => {
            window.location.reload();
        });
    }
}

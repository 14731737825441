import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { OverlayService } from '@services/overlay.service';
import { DialogService } from '@services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { DialogData } from '@models/dialog';

@Component({
    selector: 'app-remember',
    templateUrl: './remember.component.html',
    styleUrls: ['./remember.component.scss']
})
export class RememberComponent implements OnInit {

    rememberForm: FormGroup;
    submitted = false;
    dialogData: DialogData;
	overlay: boolean;
	errorMessage: string;
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private dialogService: DialogService,
        private overlayService: OverlayService
    ) {
        this.initializeRememberForm();
    }

    initializeRememberForm(){

        this.rememberForm = this.formBuilder.group({
        	email:['',[Validators.required, Validators.email]]
        });
	}
	
	get f() { return this.rememberForm.controls; }

	openDialog() {
		const dialogRef = this.dialogService.openDialog(this.dialogData, {disableClose: false});
	}

    ngOnInit() {
        this.overlayService.currentState.subscribe(state => this.overlay = state);
    }

    onSubmit() {

		this.submitted = true;
		if (this.rememberForm.invalid) {
			this.dialogData = {
				title: "",
				class: false,
				message: "Debe de ingresar un correo electrónico.",
				orderId: "",
				sgId: "",
				showOKBtn: true
			};
			this.openDialog();
			return false;
		}

		this.overlayService.changeState(true);
		this.authenticationService.sendEmail(this.rememberForm.value)
		.pipe(first())
		.subscribe(
			data => {
				this.overlayService.changeState(false);
				this.dialogData = {
					title: "",
					class: true,
					message: "Te enviamos un correo electrónico con un link para cambiar tu contraseña.",
					orderId: "",
					sgId: "",
					showOKBtn: true
				};
				this.openDialog();
			},
			error => {
				this.errorMessage = "Ha ocurrido un error inesperado, intente nuevamente o póngase en contacto con el soporte de Omnix.";
				if(error.error.code == 401) {
					this.errorMessage = "Te enviamos un correo electrónico con un link para cambiar tu contraseña."
				}
				this.overlayService.changeState(false);
				this.dialogData = {
					title: "",
					class: false,
					message: this.errorMessage,
					orderId: "",
					sgId: "",
					showOKBtn: true
				};
				this.openDialog();
			}
		);
  	}

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order } from '@models/order';
import { environment } from '@environments/environment';


/**
* Order service class
*
* @remarks
* This is te gateway class for consume Omnix API
*/
@Injectable({ providedIn: 'root' })
export class OrderService {
    
    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router
    ) { }


    /**
    * Create a new Order
    *
    * @remarks
    * This method consume Create Order method of Omnix API throgh PUT verb
    *
    * @param $fom - Form object from create-order.component.html
    * @returns JSON response with ID transaction and message response
    *
    */
    create($form) {
        //console.log($form);
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') });
        let options = { headers: headers };
        
        //Translate creationDate to ISO Format
        
        let body = $form.getRawValue();
        let creationDateISO = new Date(body.creationDate);
        let paymentInfo = [];
        body.rawAmount = Number(body.rawAmount);
        body.totalAmount = Number(body.totalAmount);
        body.discount = Number(body.discount);
        body.taxesAmount = Number(body.taxesAmount);
        body.shippingAmount = Number(body.shippingAmount);
        body.creationDate = creationDateISO.toISOString();
        body.items.forEach(item => {
            item.quantity = Number(item.quantity);
            item.rawPrice = Number(item.rawPrice);
            item.listPrice = Number(item.listPrice);
            item.salePrice = Number(item.salePrice);
            item.discount = Number(item.discount);
            if(item.custom.printTag === 'No'){
                delete item.custom.printTag;
                delete item.custom.id;
                delete item.custom.barCode;
                delete item.custom.departmentCode;
            }
            if(item.custom.printTag === 'Si'){
				delete item.custom.printTag;
			}
        });
        if(body.paymentInfo && body.paymentInfo.type && body.paymentInfo.amount){
            paymentInfo.push({
                type: body.paymentInfo.type,
                amount: Number(body.paymentInfo.amount)
            });
        } 
        body.paymentInfo = paymentInfo;
        body.billingAddress.country = body.shippingAddress.country;
        if (body.shippingType === 'SP') {
            delete body.shippingAddress;
            delete body.billingAddress;
        } else {
            delete body.storePickupId;
        }
        body = JSON.stringify(body);        
        return this.http.put<any>(
            environment.apiUrl+environment.path_createOrder+"?autoConfirm=true", body, options
        ).pipe(map(message => {
            return message;
        }));
    }

    /**
    * Create a new Order
    *
    * @remarks
    * This method consume Create Order method of Omnix API throgh PUT verb
    *
    * @param $fom - Form object from create-order.component.html
    * @returns JSON response with ID transaction and message response
    *
    */
    createOrder(body, autoConfirm = "true") {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });
        let options = { headers: headers };
        let autoConfString = "?autoConfirm="+autoConfirm
        if(autoConfirm === "false"){
            autoConfString = "";
        }        
        return this.http.put<any>(
            environment.apiUrl+environment.path_createOrder+autoConfString, body, options
        ).pipe(map(message => {
            return message;
        }));
    }

        /**
    * Create a new Order
    *
    * @remarks
    * This method consume Create Order method of Omnix API throgh PUT verb
    *
    * @param $fom - Form object from create-order.component.html
    * @returns JSON response with ID transaction and message response
    *
    */
    statusChanger(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });
        let options = { headers: headers };
        return this.http.post<any>(
            environment.apiUrl+environment.path_bulkStatus, body, options
        ).pipe(map(message => {
            return message;
        }));
    }


    /**
    * Update a transfer order
    *
    * @remarks
    * This method consume Create Order method of Omnix API through PUT verb
    *
    * @param body - JSON object
    * @returns Observable
    *
    */
    updateTransferOrder(body) {
        body.forEach(transfer => {
            transfer.items.forEach(item => {
                item.quantity = Number(item.quantity);
            });
        });
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token') });
        const options = { headers: headers };

        return this.http.put<any>(
            environment.apiUrl + environment.path_createOrder + '?thirdparty=true', body, options
        ).pipe(map(message => {
            return message;
        }));
    }

    /**
    * Get a Order by its Identification code
    *
    * @remarks
    * This method consume Get Order method of Omnix API throgh GET verb
    *
    * @param $id - ID of order
    * @returns JSON structure with order details
    *
    */
   getOrderByID($id) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });
        let options = { headers: headers };
        
        return this.http.get<any>(
            environment.apiUrl+environment.path_getOrder+$id,
            options
        ).pipe(map(order => {
            return order;
        }));
    }


	getAllSGByStatus($status, itemsPerPage, skipRecords, q) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+localStorage.getItem('token') });
		let options = { headers: headers };
		
		return this.http.post<any>(
			environment.apiUrl+environment.path_readByStatus+$status,
			{
				"skipRecords": skipRecords,
				"maxRecords": itemsPerPage,
				"query": q,
                "project": ["id", "orderId", "channel", "shippingType", "status"],
                "sort": { "_id": "desc"}
			},
			options
		).pipe(map(orders => {
			return orders;
		}));
    }
    
    getAllOrdersByStatus($status, itemsPerPage, skipRecords, q) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+localStorage.getItem('token') });
		let options = { headers: headers };
		
		return this.http.post<any>(
			environment.apiUrl+environment.path_readByOrderStatus+$status,
			{
				"skipRecords": skipRecords,
				"maxRecords": itemsPerPage,
                "query": q,
                "sort": { "_id": "desc"}
			},
			options
		).pipe(map(orders => {
			return orders;
		}));
	}


    /**
    * Get schedule information 
    *
    * @remarks
    * This method consume Get Schedule method of Omnix API throgh POST verb
    *
    * @param $channel - Sales channel to get schedule
    * @param $body   -  Json structure with search params
    * @returns JSON structure with array of schedule
    *
    */
    getSchedule($channel, $body){
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });
        let options = { headers: headers };

        return this.http.post<any>(
            environment.apiUrl+environment.path_schedule+$channel,$body,options
        ).pipe(map(response => {
            return response;
        }));
    }

    getSGByID($idOrder, $idSG)
    {
   
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });
        let options = { headers: headers };


        return this.http.get<any>(
            environment.apiUrl+environment.path_getSG+$idOrder+'/shippinggroup/'+$idSG,
            options
        ).pipe(map(orders => {
            return orders;
        }));
    }

    getAllSGsByOrderId($idOrder)
    {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });
        let options = { headers: headers };
        return this.http.get<any>(
            environment.apiUrl+environment.path_getSG+$idOrder+'/shippinggroup',
            options
        ).pipe(map(orders => {
            return orders;
        }));
    }

    SGActions($sgid, $body, $action)
    {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });

        let options = { headers: headers };


        return this.http.post<any>(
            environment.apiUrl+environment.path_getSG+'all/shippinggroup/'+$sgid+'/'+$action, 
            $body,
            options
        ).pipe(map(orders => {
            return orders;
        }));
    }

    OrderActions($orderId, $body, $action)
    {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') });

        let options = { headers: headers };


        return this.http.post<any>(
            environment.apiUrl+environment.path_getOrder+$orderId+'/'+$action, 
            $body,
            options
        ).pipe(map(orders => {
            return orders;
        }));
    }

}

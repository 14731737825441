import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RememberComponent } from './pages/auth/remember/remember.component';
import { PasswordComponent } from './pages/auth/password/password.component';

import { AuthGuard } from  './_guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
	{ path: 'login/:expired', component: LoginComponent },
  { path: 'remember', component: RememberComponent },
  { path: 'password/:token', component: PasswordComponent },
	{ path: 'home', component: AdminLayoutComponent,
		children: [{
			path: '',
			loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
		}]
	}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SchedulingMapsModule } from '@modules/scheduling-maps/scheduling-maps.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './_services/authentication.service';
//import { ErrorInterceptor } from './_helpers/error.interceptor';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { LoginComponent } from './pages/auth/login/login.component';
import { RememberComponent } from './pages/auth/remember/remember.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BulkFixedBarComponent } from '@components/bulk-fixed-bar/fixed-bar.component';
import { PasswordComponent } from './pages/auth/password/password.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    SchedulingMapsModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    RememberComponent,
    BulkFixedBarComponent,
    PasswordComponent    
  ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
